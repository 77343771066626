// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IntegrationBlock-module__paKmiz44ptaPCG0WX_yi,.IntegrationBlock-module__ejM8CxbO7UBizcTRptNH{padding:16px}.IntegrationBlock-module__paKmiz44ptaPCG0WX_yi{background-color:var(--background-secondary);border:none}.IntegrationBlock-module__ejM8CxbO7UBizcTRptNH{background:var(--background-primary);border:var(--border-weak)}.IntegrationBlock-module__lEafVencsUetnWfcKHf0{border-left:none;padding-left:0;padding-bottom:0}", "",{"version":3,"sources":["webpack://./pages/integration_2/IntegrationBlock.module.scss"],"names":[],"mappings":"AAAA,8FAEE,YAAA,CAGF,+CACE,4CAAA,CACA,WAAA,CAGF,+CACE,oCAAA,CACA,yBAAA,CAEA,+CACE,gBAAA,CACA,cAAA,CACA,gBAAA","sourcesContent":[".integrationBlock__heading,\n.integrationBlock__content {\n  padding: 16px;\n}\n\n.integrationBlock__heading {\n  background-color: var(--background-secondary);\n  border: none;\n}\n\n.integrationBlock__content {\n  background: var(--background-primary);\n  border: var(--border-weak);\n\n  &--collapsedBorder {\n    border-left: none;\n    padding-left: 0;\n    padding-bottom: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"integrationBlock__heading": "IntegrationBlock-module__paKmiz44ptaPCG0WX_yi",
	"integrationBlock__content": "IntegrationBlock-module__ejM8CxbO7UBizcTRptNH",
	"integrationBlock__content--collapsedBorder": "IntegrationBlock-module__lEafVencsUetnWfcKHf0"
};
export default ___CSS_LOADER_EXPORT___;
