// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TemplatesAlertGroupsList-module__ys2LdH1KAl19AoX0sVgN {\n  padding: 16px;\n  align-items: baseline;\n}\n\n.TemplatesAlertGroupsList-module__Ll4thx567U1XiixYPQXU {\n  width: 30%;\n  height: 100%;\n}\n\n.TemplatesAlertGroupsList-module__URO25kln42S7wbamuPsl {\n  background-color: var(--primary-background);\n  border: none;\n}\n", "",{"version":3,"sources":["webpack://./containers/TemplatesAlertGroupsList/TemplatesAlertGroupsList.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,UAAU;EACV,YAAY;AACd;;AAEA;EACE,2CAA2C;EAC3C,YAAY;AACd","sourcesContent":[".template-block-title {\n  padding: 16px;\n  align-items: baseline;\n}\n\n.template-block-list {\n  width: 30%;\n  height: 100%;\n}\n\n.alert-group-payload-view {\n  background-color: var(--primary-background);\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"template-block-title": "TemplatesAlertGroupsList-module__ys2LdH1KAl19AoX0sVgN",
	"template-block-list": "TemplatesAlertGroupsList-module__Ll4thx567U1XiixYPQXU",
	"alert-group-payload-view": "TemplatesAlertGroupsList-module__URO25kln42S7wbamuPsl"
};
export default ___CSS_LOADER_EXPORT___;
