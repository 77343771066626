// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IntegrationBlockItem-module__nN1JyDqhyhPkYZLDW4mm{display:flex;flex-direction:row;margin-bottom:12px}.IntegrationBlockItem-module__yHcs4OUzwLY6Qqc_yVaA{padding-top:12px;padding-bottom:12px}.IntegrationBlockItem-module__fkdWBbq8PD6_CDVmncWg{border-left:var(--border-medium);border-left-width:3px;margin-right:16px}", "",{"version":3,"sources":["webpack://./pages/integration_2/IntegrationBlockItem.module.scss"],"names":[],"mappings":"AAAA,mDACE,YAAA,CACA,kBAAA,CACA,kBAAA,CAEA,mDACE,gBAAA,CACA,mBAAA,CAGF,mDACE,gCAAA,CACA,qBAAA,CACA,iBAAA","sourcesContent":[".blockItem {\n  display: flex;\n  flex-direction: row;\n  margin-bottom: 12px;\n\n  &__content {\n    padding-top: 12px;\n    padding-bottom: 12px;\n  }\n\n  &__leftDelimitator {\n    border-left: var(--border-medium);\n    border-left-width: 3px;\n    margin-right: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blockItem": "IntegrationBlockItem-module__nN1JyDqhyhPkYZLDW4mm",
	"blockItem__content": "IntegrationBlockItem-module__yHcs4OUzwLY6Qqc_yVaA",
	"blockItem__leftDelimitator": "IntegrationBlockItem-module__fkdWBbq8PD6_CDVmncWg"
};
export default ___CSS_LOADER_EXPORT___;
