// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CheatSheet-module__Yp3R1WkJflJi_KFEQaHA {\n  width: 40%;\n  height: 100%;\n  padding: 16px;\n}\n\n.CheatSheet-module__V_vH6rFqwk7bnBl_SPwQ {\n  margin-bottom: 24px;\n}\n\n.CheatSheet-module__Uy6iOXmr4N4r2UpabPPA {\n  margin-bottom: 16px;\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./components/CheatSheet/CheatSheet.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,WAAW;AACb","sourcesContent":[".cheatsheet-container {\n  width: 40%;\n  height: 100%;\n  padding: 16px;\n}\n\n.cheatsheet-item {\n  margin-bottom: 24px;\n}\n\n.cheatsheet-item-small {\n  margin-bottom: 16px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cheatsheet-container": "CheatSheet-module__Yp3R1WkJflJi_KFEQaHA",
	"cheatsheet-item": "CheatSheet-module__V_vH6rFqwk7bnBl_SPwQ",
	"cheatsheet-item-small": "CheatSheet-module__Uy6iOXmr4N4r2UpabPPA"
};
export default ___CSS_LOADER_EXPORT___;
