// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CollapsedIntegrationRouteDisplay-module__Zt5QPbLPBbWNdq1qC6SM{margin-bottom:12px}", "",{"version":3,"sources":["webpack://./pages/integration_2/CollapsedIntegrationRouteDisplay.module.scss"],"names":[],"mappings":"AAAA,+DACE,kBAAA","sourcesContent":[".spacing {\n  margin-bottom: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spacing": "CollapsedIntegrationRouteDisplay-module__Zt5QPbLPBbWNdq1qC6SM"
};
export default ___CSS_LOADER_EXPORT___;
