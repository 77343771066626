// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ExpandedIntegrationRouteDisplay-module__AZHQLlDVV2bdNsgeXcdn{width:500px}.ExpandedIntegrationRouteDisplay-module__XXTMtVDG70bGob3PbZDr{width:700px}", "",{"version":3,"sources":["webpack://./pages/integration_2/ExpandedIntegrationRouteDisplay.module.scss"],"names":[],"mappings":"AACE,8DACE,WAAA,CAEF,8DACE,WAAA","sourcesContent":[".input {\n  &--short {\n    width: 500px;\n  }\n  &--long {\n    width: 700px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input--short": "ExpandedIntegrationRouteDisplay-module__AZHQLlDVV2bdNsgeXcdn",
	"input--long": "ExpandedIntegrationRouteDisplay-module__XXTMtVDG70bGob3PbZDr"
};
export default ___CSS_LOADER_EXPORT___;
