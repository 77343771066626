// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Integrations2-module__ra_wYRxE4y4No1JLtCJi{width:180px}.Integrations2-module__a7V5VJj2ldw0i8sbD1Dh{margin-bottom:24px;right:0}", "",{"version":3,"sources":["webpack://./pages/integrations_2/Integrations2.module.scss"],"names":[],"mappings":"AAAA,4CACE,WAAA,CAGF,4CACE,kBAAA,CACA,OAAA","sourcesContent":[".newIntegrationButton {\n  width: 180px;\n}\n\n.title {\n  margin-bottom: 24px;\n  right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"newIntegrationButton": "Integrations2-module__ra_wYRxE4y4No1JLtCJi",
	"title": "Integrations2-module__a7V5VJj2ldw0i8sbD1Dh"
};
export default ___CSS_LOADER_EXPORT___;
